import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useStyles from './styles';
import { useIntl } from 'react-intl';
import Button from '@material-ui/core/Button';
import { ReactComponent as DownloadIcon } from '../../../assets/icons/downloadIcon.svg';
import { ReactComponent as SendIcon } from '../../../assets/icons/sendIcon.svg';
import { ReactComponent as ListIcon } from '../../../assets/icons/listIcon.svg';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import * as Yup from 'yup';
import FormInput from '../../components/global/FormInput';
import { show } from 'redux-modal';
import { PERSONAL_AREA_CHANGE_PASSWORD } from '../../constants/modalNames';
import PersonalAreaChangePasswordModal from '../../components/modals/PersonalAreaChangePasswordModal';
import PersonalAreaSuccessChangePasswordModal from '../../components/modals/PersonalAreaSuccessChangePasswordModal';
import {
  editUserAsync,
  fetchGetDiploma,
  fetchSendDiploma,
} from '../../../store/actions/userActions';
import { fetchContentsListAsync } from '../../../store/actions/contentsActions';
import Loading from '../../components/global/Loading';
import { getControllFildsValue } from '../../../services/getControllFildsValue';
import Flex from '../../components/global/Flex';
import { Checkbox } from '@material-ui/core';
import { ErrorMessage } from '@hookform/error-message';

const PersonalArea = () => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const matches768 = useMediaQuery(`(max-width:768px)`);
  const onSubmit = (data) => {
    dispatch(editUserAsync(data));
  };
  // Button show correction
  const may21st = new Date('May 21 2021 18:00:00 GMT+0300').getTime();
  const today = new Date().getTime();
  const showButtons = today >= may21st;
  // ------------------------
  const user = useSelector((state) => state.user.user);

  const diplomaLink = useSelector((state) => state.user.diplomaLink);

  const contentsList = useSelector((state) => state.contents.fields);
  const loading = useSelector((state) => state.contents.loading);

  const smeBtnValue = getControllFildsValue(contentsList, 'cme-btn', 'cme-btn');
  const diplomBtnValue = getControllFildsValue(
    contentsList,
    'diplom-btn',
    'diplom-btn',
  );
  const isShowDiplomaBtn = user?.ableToDownloadDiploma;

  const language = user.language;
  const validationSchema = Yup.object().shape({
    lastName: Yup.string(),
    firstName: Yup.string(),
    country: Yup.string().required(
      formatMessage({ id: 'inputErrors_required' }),
    ),
    city: Yup.string().required(formatMessage({ id: 'inputErrors_required' })),
    placeOfWork: Yup.string().required(
      formatMessage({ id: 'inputErrors_required' }),
    ),
    position: Yup.string().required(
      formatMessage({ id: 'inputErrors_required' }),
    ),
    email: Yup.string()
      .email(formatMessage({ id: 'inputErrors_required_emil' }))
      .required(formatMessage({ id: 'inputErrors_required' })),
    birthDate: Yup.string().required(
      formatMessage({ id: 'inputErrors_required' }),
    ),
  });
  const { handleSubmit, control, errors, watch } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: user,
  });
  useEffect(() => {
    dispatch(fetchGetDiploma());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    dispatch(fetchContentsListAsync);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const birthDateValue = watch('birthDate');

  if (loading) return <Loading />;

  return (
    <div>
      <section className={classes.section}>
        <PersonalAreaChangePasswordModal />
        <PersonalAreaSuccessChangePasswordModal />
        <Container style={{ flexDirection: 'column' }}>
          <div className={classes.personalAreaInfo}>
            <div>
              <Typography variant='h3'>
                {formatMessage({ id: 'personal_area_title' })}
              </Typography>
            </div>
            {!matches768 && showButtons && (
              <div className={classes.personalAreaButtonsWrapper}>
                <>
                  {isShowDiplomaBtn && diplomBtnValue ? (
                    <Link
                      href={diplomaLink}
                      className={classes.linkStyle}
                      target='_blank'
                    >
                      <Button
                        variant='contained'
                        color='secondary'
                        className={classes.personalAreaButton}
                      >
                        {formatMessage({ id: 'personal_area_button_download' })}
                        <DownloadIcon />
                      </Button>
                    </Link>
                  ) : null}

                  {isShowDiplomaBtn && diplomBtnValue ? (
                    <Button
                      variant='contained'
                      color='secondary'
                      className={classes.personalAreaButton}
                      onClick={() => dispatch(fetchSendDiploma())}
                    >
                      {formatMessage({ id: 'personal_area_button_send' })}
                      <SendIcon />
                    </Button>
                  ) : null}

                  {smeBtnValue ? (
                    <Link
                      href={getControllFildsValue(
                        contentsList,
                        'cme-btn-link-ua',
                        'cme-btn-link-en',
                      )}
                      className={classes.linkStyle}
                      target='_blank'
                    >
                      <Button
                        variant='contained'
                        color='secondary'
                        className={classes.personalAreaButton}
                      >
                        {getControllFildsValue(
                          contentsList,
                          'cme-btn-text-ua',
                          'cme-btn-text-en',
                        )}
                        <ListIcon className={classes.icon} />
                      </Button>
                    </Link>
                  ) : null}
                </>
              </div>
            )}
          </div>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
            <Typography variant='subtitle1'>
              {formatMessage({ id: 'personal_area_subtitle1' })}
            </Typography>

            <div className={classes.personalAreaFieldWrapper}>
              <div className={classes.personalAreaField}>
                <div className={classes.label}>
                  {formatMessage({ id: 'form_last_name' })}
                </div>
                <FormInput
                  error={errors.lastName}
                  disabled
                  errors={errors}
                  name='lastName'
                  control={control}
                  fullWidth
                />
              </div>
              <div className={classes.personalAreaField}>
                <div className={classes.label}>
                  {formatMessage({ id: 'form_first_name' })}
                </div>
                <FormInput
                  error={errors.firstName}
                  disabled
                  errors={errors}
                  name='firstName'
                  control={control}
                  fullWidth
                />
              </div>
              <div className={classes.personalAreaField}>
                <div className={classes.label}>
                  {formatMessage({ id: 'form_middle_name' })}
                </div>
                <FormInput
                  errors={errors}
                  disabled
                  name='middleName'
                  control={control}
                  fullWidth
                />
              </div>
            </div>
            <Typography variant='subtitle1'>
              {formatMessage({ id: 'personal_area_subtitle2' })}
            </Typography>
            <div
              className={`${classes.personalAreaFieldWrapper} ${classes.personalAreaFieldWrapperBottom}`}
            >
              <div className={classes.personalAreaField}>
                <div className={classes.label}>
                  {formatMessage({ id: 'form_country' })}
                </div>
                <FormInput
                  error={errors.country}
                  errors={errors}
                  name='country'
                  control={control}
                  fullWidth
                />
              </div>
              <div className={classes.personalAreaField}>
                <div className={classes.label}>
                  {formatMessage({ id: 'form_city' })}
                </div>
                <FormInput
                  error={errors.city}
                  errors={errors}
                  name='city'
                  control={control}
                  fullWidth
                />
              </div>
              <div className={classes.personalAreaField}>
                <div className={classes.label}>
                  {formatMessage({ id: 'form_work_place' })}
                </div>
                <FormInput
                  error={errors.placeOfWork}
                  errors={errors}
                  name='placeOfWork'
                  control={control}
                  fullWidth
                />
              </div>
            </div>
            <div
              className={`${classes.personalAreaFieldWrapper} ${classes.personalAreaFieldWrapperBottom}`}
            >
              <div className={classes.personalAreaField}>
                <div className={classes.label}>
                  {formatMessage({ id: 'form_post' })}
                </div>
                <FormInput
                  error={errors.position}
                  errors={errors}
                  name='position'
                  control={control}
                  fullWidth
                />
              </div>
              <div className={classes.personalAreaField}>
                <div className={classes.label}>
                  {formatMessage({ id: 'form_email' })}
                </div>
                <FormInput
                  error={errors.email}
                  errors={errors}
                  name='email'
                  control={control}
                  fullWidth
                />
              </div>

              <div className={classes.personalAreaField}>
                <div className={classes.label}>
                  {formatMessage({ id: 'form_birth_date' })}
                </div>
                <FormInput
                  error={errors.birthDate}
                  errors={errors}
                  name='birthDate'
                  control={control}
                  fullWidth
                  type='date'
                  value={birthDateValue}
                  label={formatMessage({ id: 'form_birth_date' })}
                />
              </div>
            </div>

            <Flex xs='100%' style={{ marginBottom: '20px' }}>
              <label className={classes.checkboxLabel}>
                <Controller
                  render={(props) => {
                    return (
                      <Checkbox
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    );
                  }}
                  name='isOnlineParticipant'
                  control={control}
                  id='isOnlineParticipant'
                />
                {formatMessage({ id: 'form_is_online_checkbox_text' })}
              </label>
              <ErrorMessage
                render={({ message }) => (
                  <div style={{ color: 'red', marginTop: 3 }}>{message}</div>
                )}
                errors={errors}
                name='isOnlineParticipant'
              />
            </Flex>
            <div className={classes.personalAreaField}>
              <Button
                variant='contained'
                color='secondary'
                className={classes.personalAreaButtonChange}
                onClick={() => dispatch(show(PERSONAL_AREA_CHANGE_PASSWORD))}
              >
                {formatMessage({ id: 'personal_area_button_change' })}
              </Button>
            </div>

            <Button
              type='submit'
              variant='contained'
              color='primary'
              className={classes.personalAreaButtonSave}
            >
              {formatMessage({ id: 'personal_area_button_save' })}
            </Button>
          </form>
          {matches768 && showButtons && (
            <div
              className={classes.personalAreaButtonsWrapper}
              style={{
                flexDirection: 'column',
                marginTop: 20,
                alignItems: 'flex-start',
              }}
            >
              {isShowDiplomaBtn && diplomBtnValue ? (
                <Link
                  href={diplomaLink}
                  className={classes.linkStyle}
                  target='_blank'
                >
                  <Button
                    variant='contained'
                    color='secondary'
                    className={classes.personalAreaButton}
                    style={{ marginBottom: 20 }}
                  >
                    {formatMessage({ id: 'personal_area_button_download' })}
                    <DownloadIcon />
                  </Button>
                </Link>
              ) : null}

              {isShowDiplomaBtn && diplomBtnValue ? (
                <Button
                  variant='contained'
                  color='secondary'
                  className={classes.personalAreaButton}
                  onClick={() => dispatch(fetchSendDiploma())}
                >
                  {formatMessage({ id: 'personal_area_button_send' })}
                  <SendIcon />
                </Button>
              ) : null}

              {smeBtnValue ? (
                <Link
                  href={getControllFildsValue(
                    contentsList,
                    'cme-btn-link-ua',
                    'cme-btn-link-en',
                  )}
                  className={classes.linkStyle}
                  target='_blank'
                >
                  <Button
                    variant='contained'
                    color='secondary'
                    className={classes.personalAreaButton}
                    style={{ marginTop: 0, marginBottom: 50 }}
                  >
                    {getControllFildsValue(
                      contentsList,
                      'cme-btn-text-ua',
                      'cme-btn-text-en',
                    )}
                    <ListIcon />
                  </Button>
                </Link>
              ) : null}
            </div>
          )}
        </Container>
      </section>
    </div>
  );
};

export default PersonalArea;
