import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connectModal, hide } from 'redux-modal';
import useIntl from 'react-intl/lib/src/components/useIntl';
import { SIGN_UP } from '../../../constants/modalNames';
import useStyles from './styles';
import TabsItem from '../../../components/global/TabsItem';
import { ReactComponent as KeyIcon } from '../../../../assets/icons/keyIcon.svg';
import FirstStep from './FirstStep';
import SecondStep from './SecondStep';
import ThirdStep from './ThirdStep';
// Redux
import { useDispatch, useSelector } from 'react-redux';
// Api
import actions from '../../../../store/actions';
import { LOCALES } from '../../../../lang';

const { registerWithEmailPasswordAsync } = actions;

const SignUpModal = ({ show }) => {
  const [currentTab, setCurrentTab] = useState(0);
  const locale = useSelector((state) => state.locale.language);
  const [formData, setFormData] = useState({
    birthDate: '',
    lastName: '',
    firstName: '',
    middleName: '',
    password: '',
    email: '',
    placeOfWork: '',
    country: '',
    city: '',
    position: '',
    language: locale === LOCALES.ENGLISH ? 'EN' : 'UA', // Change to localStorageSaved/redux value
    checkbox: false,
    isOnlineParticipant: false,
  });
  const classes = useStyles({ currentTab });
  const isLoading = useSelector((state) => state.user.loading);
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const onFirstSubmit = (values) => {
    setFormData({ ...formData, ...values });
    setCurrentTab(1);
  };

  const onSecondSubmit = (values) => {
    setFormData({ ...formData, ...values });
    setCurrentTab(2);
  };
  const onThirdSubmit = (values) => {
    delete values.checkbox;
    delete formData.checkbox;
    dispatch(registerWithEmailPasswordAsync({ ...formData, ...values }));
  };
  return (
    <div>
      <Dialog
        open={show}
        onClose={() => dispatch(hide(SIGN_UP))}
        maxWidth='xl'
        className={classes.dialog}
      >
        <DialogContent className={classes.body}>
          {isLoading ? (
            <CircularProgress color='primary' />
          ) : (
            <>
              <div className={classes.modalImage}>
                <KeyIcon />
              </div>
              <Typography className={classes.title} variant='h4' align='center'>
                {formatMessage({ id: 'sign_up_form_title' })}
              </Typography>
              <div className={classes.tabsNav}>
                <div className={classes.tabsQuantity}>
                  {`${currentTab + 1} ${formatMessage({
                    id: 'sign_up_form_step_quantity',
                  })}`}
                </div>
                <div className={classes.lineWrapper}>
                  <div className={classes.lineInner} />
                </div>
              </div>
              <TabsItem currentTab={currentTab} index={0}>
                <FirstStep onSubmit={onFirstSubmit} formData={formData} />
              </TabsItem>
              <TabsItem currentTab={currentTab} index={1}>
                <SecondStep
                  onSubmit={onSecondSubmit}
                  setCurrentTab={setCurrentTab}
                  formData={formData}
                />
              </TabsItem>
              <TabsItem currentTab={currentTab} index={2}>
                <ThirdStep
                  onSubmit={onThirdSubmit}
                  setCurrentTab={setCurrentTab}
                  formData={formData}
                />
              </TabsItem>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default connectModal({
  name: SIGN_UP,
  getModalState: (state) => state.modals,
})(SignUpModal);
